import dinero from "dinero.js";

const Dinero = (jsObj) => {
  const obj = { ...jsObj };
  if (obj.currency_code) obj.currency = obj.currency_code;
  if (obj.precision === undefined) {
    const zeroPrecisionList = [
      "BIF",
      "CLP",
      "DJF",
      "GNF",
      "ISK",
      "JPY",
      "KMF",
      "KRW",
      "PYG",
      "RWF",
      "UGX",
      "UYI",
      "VND",
      "VUV",
      "XAF",
      "XOF",
      "XPF",
    ];
    const threePrecisionList = [
      "BHD",
      "IQD",
      "JOD",
      "KWD",
      "LYD",
      "OMR",
      "TND",
    ];
    const fourPrecisionList = ["CLF", "UYW"];

    let precision;
    if (zeroPrecisionList.includes(obj.currency)) {
      precision = 0;
    } else if (threePrecisionList.includes(obj.currency)) {
      precision = 3;
    } else if (fourPrecisionList.includes(obj.currency)) {
      precision = 4;
    } else precision = 2;

    return dinero({ ...obj, precision });
  }

  return dinero(obj);
};

export default Dinero;
