import React from "react";
import MuiSnackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "reduxStore/features/ui-slice";

const StyledMuiSnackbar = styled(MuiSnackbar)(() => ({
  "&.MuiSnackbar-anchorOriginTopLeft": {
    top: 0,
    left: 0,
    right: 0,
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  "&.MuiAlert-root": {
    width: "100%",
    padding: theme.spacing(1.25, 2),
    borderRadius: 0,
  },
}));

const SnackBar = () => {
  const dispatch = useDispatch();
  const { message, open, severity, duration, messageComponent } = useSelector(
    (state) => state.ui.snackbar
  );
  const handleSnackbarClose = () => {
    dispatch(
      setSnackbar({
        open: false,
      })
    );
  };
  return (
    <StyledMuiSnackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleSnackbarClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <StyledAlert
        elevation={0}
        variant="filled"
        onClose={handleSnackbarClose}
        severity={severity}
        sx={{ color: `${severity === "warning" ? "#333" : "#fff"}` }}
      >
        {message ? (
          <span dangerouslySetInnerHTML={{ __html: message }} />
        ) : (
          <span>{messageComponent}</span>
        )}
      </StyledAlert>
    </StyledMuiSnackbar>
  );
};

export { SnackBar };
