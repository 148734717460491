import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  "&.CPRoot": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    "& .MuiPagination-root": {
      [theme.breakpoints.down("md")]: {
        flex: "0 0 100%",
      },
    },
    "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        fontWeight: 500,
        textDecoration: "underline",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  "& .MuiPaginationItem-previousNext": {
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      minWidth: "unset",
    },
  },
}));

export default StyledBox;
