export const CONTACTS = {
  phone: "(888) 406-6955",
  email: " ERDX@eaglerider.com",
};

export const ACTIONS = {
  change: "change",
  remove: "remove",
  select: "select",
};
export const LOCAL_STORAGE_ITEMS = {
  current_Dealer: "currentDealer",
  cart_Size: "cartSize",
  active_Order_Id: "activeOrderId",
  order_Price: "orderPrice",
};

export const INFO_TYPE = {
  digit_Box: "digitBox",
  chip: "chip",
  typography: "typography",
};

export const FIELDS_NAME = {
  price: "price",
  mileage_Array: "mileage_array",
};
