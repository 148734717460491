import React, { useMemo } from "react";
import {
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Avatar,
  IconButton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";
import StyledDrawer from "views/common/components/UI/UserDrawer/UserDrawer.styles";
import { useTranslation } from "next-i18next";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import LogoutIcon from "@mui/icons-material/Logout";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import NavigatorLink from "views/common/components/Logical/NavigatorLink";
import axios, { API } from "lib/config/axios";
import { useDispatch, useSelector } from "react-redux";
import PATHS from "lib/navigation/paths";
import { DealerChangeButton } from "views/common/components/Logical";
import { useErrorsHandler } from "views/common/hooks";
import useAppNavigator from "views/common/hooks/useAppNavigator";
import { CONTACTS } from "views/common/utils/constants";

const { envVars } = require("next/config").default().publicRuntimeConfig;

const UserDrawer = ({ open, handleCloseDrawer }) => {
  const { t } = useTranslation("common_layout");
  const appState = useSelector((state) => state.globalData.appState);
  const phone = CONTACTS.phone;
  const user = appState.currentUser;
  const handleErrorResponse = useErrorsHandler();
  const appNavigator = useAppNavigator();
  // states

  const handleSignOut = () => {
    axios
      .get(API.users.signOut, { baseURL: envVars.ER_WEBSITE_BASE })
      .then(() => {
        localStorage.clear();

        appNavigator.push("/erdx");
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  const userDrawerList = useMemo(() => {
    const list = [
      {
        text: t("common_layout:userDrawer.inventory"),
        icon: <TwoWheelerIcon />,
        href: PATHS.vehicles.index(),
      },
      {
        text: t("common_layout:userDrawer.my_account"),
        icon: <PersonIcon />,
        href: PATHS.personalInfo(),
      },
      {
        text: t("common_layout:userDrawer.purchased_motorcycles"),
        icon: <CreditScoreIcon />,
        href: PATHS.orders.index(),
      },
      {
        text: phone,
        icon: <LocalPhoneIcon />,
        display: "showTablet",
        href: `tel:${phone}`,
      },
    ];

    if (user?.dealers_count != 1) {
      list.push({
        display: "showTablet",
        component: <DealerChangeButton />,
      });
    }

    list.push({
      text: t("common_layout:userDrawer.logout"),
      icon: <LogoutIcon />,
      handleClick: handleSignOut,
    });

    return list;
  }, [appState]);

  return (
    <>
      <StyledDrawer anchor="right" open={open} onClose={handleCloseDrawer}>
        <Box role="presentation">
          <Box className="UDDrawerHeader">
            <Box className="UDrHeaderUserInfo">
              <Avatar
                className="UDHeaderAvatar"
                src={user.avatar_url || "/erdx/images/default.png"}
                alt={user.first_name}
              />
              <Box className="UDHeaderAvatarInfo">
                <span className="UDCaption">{t("common_layout:welcome")}</span>
                <Box variant="h6">
                  {user.first_name} {user.last_name}
                </Box>
              </Box>
            </Box>
            <IconButton onClick={handleCloseDrawer} size="large">
              <CloseIcon className="UDCloseIcon" />
            </IconButton>
          </Box>
          <List className="UDUserMenu">
            {userDrawerList.map((item, index) => (
              <ListItem
                className={`UDListItem ${
                  item.display === "showTablet" ? "showTABLET" : ""
                }`}
                key={index} // Use index as the key
                disablePadding
              >
                {item.component ? (
                  item.component
                ) : (
                  <ListItemButton
                    component={NavigatorLink}
                    to={item.href}
                    onClick={() => {
                      item.handleClick?.();
                      handleCloseDrawer();
                    }}
                  >
                    <ListItemIcon> {item.icon}</ListItemIcon>
                    <ListItemText
                      primary={t(item.text)}
                      secondary={t(item.subText)}
                      className="UDListItemText"
                    />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      </StyledDrawer>
    </>
  );
};

export { UserDrawer };
