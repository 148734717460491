/* eslint-disable global-require */
/* eslint-disable consistent-return */
// skipcq: JS-C1003
import * as Airbrake from '@airbrake/node';

let airbrake;

export const initAirbrake = (initialEnvVars) => {
  let envVars = initialEnvVars;
  if (!envVars) {
    envVars = require('next/config').default().publicRuntimeConfig.envVars;
  }
  if (envVars.ENVIRONMENT == 'prelive' || envVars.ENVIRONMENT == 'production') {
    if (envVars.AIRBRAKE_PROJECT_ID && envVars.AIRBRAKE_PROJECT_KEY) {
      airbrake = new Airbrake.Notifier({
        projectId: envVars.AIRBRAKE_PROJECT_ID,
        projectKey: envVars.AIRBRAKE_PROJECT_KEY,
        environment: `${envVars.ENVIRONMENT} ${
          typeof window === 'undefined' ? '[SSR]' : '[CSR]'
        }`,
      });

      airbrake.addFilter((notice) => {
        if (
          notice.errors[0].message.includes(
            '_$_google_map_initialize_$_ is not a function',
          )
          || notice.errors[0].message.includes('insertBefore')
          || notice.errors[0].message.includes('Failed to preflight request')
        ) {
          // Ignore errors with this messsage
          return null;
        }

        return notice;
      });
    }
  }
};

export const notifyAirbrake = (error) => {
  if (airbrake) {
    return airbrake.notify(error);
  }
};
