/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appState: {},
};

export const globalDataSlice = createSlice({
  name: 'globalData',
  initialState,
  reducers: {
    setAppData: (state, action) => {
      state.appState = action.payload;
    },
    resetGlobalDataSlice: () => initialState,
  },
});

export const {
  setAppData,
  resetGlobalDataSlice,
} = globalDataSlice.actions;

export default globalDataSlice.reducer;
