import React from "react";
import StyledTextField from "views/common/components/UI/LightTextField/LightTextField.styles";

const LightTextField = (props) => {
  const { whiteBackground, children, ...otherProps } = props;

  return (
    <StyledTextField
      whiteBackground={whiteBackground}
      variant="filled"
      {...otherProps}
    >
      {children}
    </StyledTextField>
  );
};

export { LightTextField };
