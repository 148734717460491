import React from "react";
import MuiLink from "@mui/material/Link";
import { CURRENT_APP } from "lib/navigation/apps";
import useAppNavigator from "views/common/hooks/useAppNavigator";
import Link from "next/link";

const NavigatorLink = (props, ref) => {
  const { href, locale, children, ...restOfProps } = props;
  const appNavigator = useAppNavigator();

  // Directly handle tel: links
  if (typeof href === "string" && href.startsWith("tel:")) {
    return (
      <MuiLink href={href} ref={ref} {...restOfProps}>
        {children}
      </MuiLink>
    );
  }

  const link =
    href &&
    appNavigator.appendQuery(appNavigator.getUrl(locale, href), href?.query);
  if (typeof href === "object" && !link.startsWith("http")) {
    if (href.app === CURRENT_APP) {
      return (
        <>
          <Link {...restOfProps} prefetch={false} href={link} locale={locale}>
            {children}
          </Link>
        </>
      );
    }
  }
  return (
    <>
      <MuiLink href={link} ref={ref} {...restOfProps}>
        {children}
      </MuiLink>
    </>
  );
};

export default React.memo(React.forwardRef(NavigatorLink));
