import React from "react";
import StyledButton from "views/common/components/UI/AddAndRemoveCartBtn/AddAndRemoveCartBtn.styles";
import { useTranslation } from "next-i18next";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import axios, { API } from "lib/config/axios";
import { setLoading, setSnackbar } from "reduxStore/features/ui-slice";
import { useDispatch, useSelector } from "react-redux";
import { useErrorsHandler } from "views/common/hooks";
import { setAppData } from "reduxStore/features/globalData-slice";
import { LOCAL_STORAGE_ITEMS } from "views/common/utils/constants";
import { useTheme, useMediaQuery } from "@mui/material";

const AddAndRemoveCartBtn = (props) => {
  const {
    inCart,
    toggleInCartState,
    vehicleId,
    handleRemoveVehicle,
    isCartView = false,
    fixedBar = false,
    fullWidth = true,
  } = props;
  const { t } = useTranslation("common_card_actions");
  const appState = useSelector((state) => state.globalData.appState);
  const authenticityToken = appState.authenticityToken;
  const activeOrderId = appState.activeOrderId;
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md")); // Detect if screen is medium or smaller
  const handleErrorResponse = useErrorsHandler();
  const dispatch = useDispatch();

  const handleAddToCartClickBtn = () => {
    dispatch(setLoading(true));
    const url = API.orders.id.replace("{id}", activeOrderId);
    const payload = {
      authenticity_token: authenticityToken,
      vehicle_id: vehicleId,
    };

    axios
      .put(url, payload)
      .then((res) => {
        const cartSize = res.data.order_new_items_count;
        const orderPrice = res.data.order_new_price;
        dispatch(
          setAppData({
            ...appState,
            cartSize: cartSize,
            orderPrice: orderPrice,
          })
        );
        localStorage.setItem(LOCAL_STORAGE_ITEMS.cart_Size, cartSize);
        localStorage.setItem(
          LOCAL_STORAGE_ITEMS.order_Price,
          JSON.stringify(orderPrice)
        );
        isCartView ? handleRemoveVehicle(vehicleId) : toggleInCartState();
      })
      .catch((error) => {
        switch (error.response.status) {
          case 406:
            if (error.response.data.errors) {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: "error",
                  message: error.response.data.errors,
                })
              );
            }
            break;
          default:
            handleErrorResponse(error);
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <StyledButton
      fullWidth={fullWidth}
      className={fixedBar ? "ARBIconBtn" : ""}
      color="secondary"
      variant={fixedBar ? "outlined" : "contained"}
      startIcon={
        inCart ? <RemoveShoppingCartIcon /> : <AddShoppingCartOutlinedIcon />
      }
      onClick={handleAddToCartClickBtn}
    >
      {!fixedBar &&
        isMediumScreen &&
        (inCart
          ? t("common_card_actions:remove_from_cart")
          : t("common_card_actions:add_to_cart"))}
    </StyledButton>
  );
};

export { AddAndRemoveCartBtn };
