import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "&.COLRoot": {
    "& .MuiPaper-root": {
      "&.MuiDialog-paperWidthLg": {
        "@media only screen and (min-width: 1250px)": {
          minWidth: theme.spacing(150),
        },
        "@media only screen and (max-width: 1250px) and (min-width: 600px)": {
          width: "100%",
        },
      },
      "&.MuiDialog-paperWidthMd": {
        [theme.breakpoints.up("md")]: {
          minWidth: theme.spacing(69),
        },
        [theme.breakpoints.up("lg")]: {
          minWidth: theme.spacing(81),
        },
      },
    },

    "& .MuiDialogTitle-root": {
      fontWeight: 500,
      fontSize: theme.spacing(2.5),
      padding: theme.spacing(2, 3),
      position: "relative",
      minHeight: theme.spacing(9),
      lineHeight: theme.spacing(5),
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(2, 7, 2, 2), // add extra space on right to  fix  title overlaps closeBTn
        fontSize: theme.spacing(2.5),
      },
    },
    "& .COLClose": {
      padding: theme.spacing(0.5),
      position: "absolute",
      right: theme.spacing(3),
      top: theme.spacing(3.5),
      [theme.breakpoints.down("md")]: {
        top: theme.spacing(2.5),
      },
      "& .MuiSvgIcon-root": {
        fontSize: theme.spacing(3),
      },
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0, 3, 3),
    },
    "& .COLDialogActionsWrapper": {
      gap: theme.spacing(2),
      display: "flex",
    },
    "& .COLDialogActions": {
      padding: theme.spacing(1.5),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1.5, 3, 3),
      },
    },
  },
  "&.COLVisibleOverflow": {
    "& .MuiPaper-root": {
      [theme.breakpoints.up("md")]: {
        overflowY: "visible",
      },
    },
    "& .MuiDialogContent-root": {
      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(13),
      },
    },
  },
}));

export default StyledDialog;
