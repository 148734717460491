import React from 'react';
import StyledTypography from 'views/common/components/UI/Typography700/Typography700.styles';

const Typography700 = (props) => {
  const { children, ...other } = props;

  return <StyledTypography {...other}>{children}</StyledTypography>;
};

export { Typography700 };
