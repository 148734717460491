import React, { useEffect } from "react";
import {
  LoadingSpinner,
  SnackBar,
  TopBar,
  Footer,
} from "views/common/components/UI";
import { useSelector } from "react-redux";
import { StyledMain } from "views/common/components/Logical/Layout/Layout.styles";
import PATHS from "lib/navigation/paths";
import useAppNavigator from "views/common/hooks/useAppNavigator";
import { Box } from "@mui/material";
import dynamic from "next/dynamic";

const ErrorPage = dynamic(() =>
  import("views/common/components/Logical/ErrorPage").then(
    (file) => file.ErrorPage
  )
);

const Layout = (props) => {
  const { children, skipRedirect = false, hideLayout = false } = props;
  const appState = useSelector((state) => state.globalData.appState);
  const currentUser = appState.currentUser;
  const appNavigator = useAppNavigator();
  const error = useSelector((state) => state.ui.error);
  const isRedirecting =
    Object.keys(appState).length > 0 && !currentUser && !skipRedirect;
  useEffect(() => {
    // Check if there's no currentUser and the redirection should not be skipped
    if (isRedirecting) {
      appNavigator.push(PATHS.home()); // Redirect to home page
    }
  }, [isRedirecting]);

  return (
    <>
      {
        // Prevent rendering the page until the redirection is completed.
        // This ensures that the user is properly redirected before any page content is displayed,
        // preventing race conditions where the page might render prematurely while the navigation is still in
      }
      {!isRedirecting && (
        <>
          {Object.keys(appState).length > 0 && !hideLayout && <TopBar />}
          <StyledMain>
            {error?.statusCode && (
              <ErrorPage
                statusCode={error.statusCode}
                title={error.title}
                subtitle={error.subtitle}
              />
            )}
            <LoadingSpinner />
            <SnackBar />
            {Object.keys(appState).length > 0 && (
              <Box hidden={!!error?.statusCode} width="100%">
                {children}
              </Box>
            )}
          </StyledMain>
          {Object.keys(appState).length > 0 && !hideLayout && <Footer />}
        </>
      )}
    </>
  );
};

export { Layout };
