import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({ theme, withSubTitle }) => ({
  margin: theme.spacing(8, 0, 4),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  gap: theme.spacing(1),
  flexDirection: "column",
  color: theme.palette.grey[900],
  "& .PTTitle": {
    fontSize: withSubTitle ? theme.typography.pxToRem(21) : theme.typography.h3,
    [theme.breakpoints.down("md")]: {
      fontSize: withSubTitle
        ? theme.typography.pxToRem(18)
        : theme.typography.h3,
      lineHeight: withSubTitle
        ? theme.typography.pxToRem(28)
        : theme.typography.h3,
    },
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
  },
  [theme.breakpoints.down("xl")]: {
    margin: theme.spacing(6, 0, 4),
  },
  [theme.breakpoints.down("lg")]: {
    margin: theme.spacing(4, 0),
  },
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(2, 0),
  },
  "& .PTBtn": {
    "&.MuiButton-outlinedSecondary": {
      borderColor: theme.palette.grey[900],
    },
  },
}));

export default StyledBox;
