import React from "react";
import StyledBox from "views/common/components/UI/PageTitle/PageTitle.styles";
import { Box, Button } from "@mui/material";
import { Typography500 } from "views/common/components/UI";

const PageTitle = (props) => {
  const { title, subTitle, buttons } = props;

  return (
    <StyledBox withSubTitle={subTitle}>
      <Box>
        <Typography500 component="h3" variant="h3" className="PTTitle">
          {title}
        </Typography500>
        {subTitle}
      </Box>
      {buttons?.map((btn) => (
        <Button
          variant={btn.variant}
          key={btn.text}
          color={btn.color}
          className={`${btn.className} PTBtn`}
          onClick={btn.onClick}
          href={btn.href}
          component={btn.component}
        >
          {btn.text}
        </Button>
      ))}
    </StyledBox>
  );
};

export { PageTitle };
