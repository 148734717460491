import React from "react";
import StyledButton from "views/common/components/UI/TopBar/TopBarDarkButton/TopBarDarkButton.styles";
import NavigatorLink from "views/common/components/Logical/NavigatorLink";

const TopBarDarkButton = (props) => {
  const { icon, text, href, className } = props;
  return (
    <NavigatorLink href={href}>
      <StyledButton
        className={className}
        variant="contained"
        color="secondary"
        edge="start"
        startIcon={icon}
        disableElevation
      >
        {text}
      </StyledButton>
    </NavigatorLink>
  );
};

export { TopBarDarkButton };
