import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const StyledTextField = styled(TextField)(({ theme, whiteBackground }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: whiteBackground
      ? theme.palette.common.white
      : "transparent",
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 0,
    transition: "all .5s ease",
    "&.Mui-focused": {
      border: `1px solid ${theme.palette.grey[500]}`,
      backgroundColor: whiteBackground
        ? theme.palette.common.white
        : "transparent",
      "&::before, after": {
        borderColor: whiteBackground
          ? `${theme.palette.common.white} !important`
          : "transparent",
      },
    },
    "&.Mui-error": {
      border: `1px solid ${theme.palette.grey[100]}`,
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[100]}`,
      "&.MuiFilledInput-root:hover": {
        border: `1px solid ${theme.palette.grey[100]}`,
        backgroundColor: theme.palette.grey[50],
      },
    },
    "&::before, after": {
      borderColor: whiteBackground
        ? `${theme.palette.common.white} !important`
        : "transparent !important",
      backgroundColor: "transparent",
    },
    "&.MuiFilledInput-root:hover": {
      border: `1px solid ${theme.palette.grey[500]}`,
      backgroundColor: whiteBackground
        ? theme.palette.common.white
        : "transparent",
    },
    "&.MuiFilledInput-root:after": {
      borderBottomWidth: 1,
    },
  },
  "& .MuiFormLabel-root": {
    color: theme.palette.grey[200],
  },
}));

export default StyledTextField;
