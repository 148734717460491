/* eslint-disable mui-unused-classes/unused-classes */
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material";
import theme from "lib/config/theme";
import { alpha } from "@mui/material/styles";

const GlobalTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@media print": {
          "@page": {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          },
        },
        body: {
          overflowX: "hidden", // for right/left slided popups
          fontSize: "0.875rem",
          backgroundColor: "#fafafa",
          lineHeight: "1.4375rem",
          "& .showTABLET": {
            [theme.breakpoints.up("lg")]: {
              display: "none !important",
            },
          },
          "& .showMOBILE": {
            [theme.breakpoints.up("md")]: {
              display: "none !important",
            },
          },
          "&  .showTabletAndDesktop": {
            [theme.breakpoints.down("md")]: {
              display: "none !important",
            },
          },
          "& .showLargeDESKTOP": {
            [theme.breakpoints.down("xl")]: {
              display: "none !important",
            },
          },
          "&  .showMobileAndTabletAndDesktop": {
            [theme.breakpoints.up("xl")]: {
              display: "none !important",
            },
          },
          "& .showDESKTOP": {
            [theme.breakpoints.down("lg")]: {
              display: "none !important",
            },
          },

          // Condition Score Chip
          "& .ConditionScoreChip": {
            backgroundColor: theme.palette.common.fogWhite,
            color: theme.palette.primary.main,
            fontWeight: "bold",
            padding: theme.spacing(0.25, 1),
            height: theme.spacing(2.25),
            fontSize: theme.typography.pxToRem(14),
            "& .ConditionScoreChipIcon": {
              color: theme.palette.primary.contrastText,
              margin: 0,
              fontSize: theme.typography.pxToRem(14),
            },
            "& .MuiChip-label": {
              paddingRight: 0,
              paddingLeft: theme.spacing(0.5),
              color: theme.palette.primary.contrastText,
            },
          },
        },
        "& .pageLayout": {
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          minHeight: "100vh",
          width: "100%",
          ".MuiContainer-root": {
            [theme.breakpoints.down("md")]: {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            },
          },
        },
        ".MuiOutlinedInput-input.Mui-disabled": {
          background: "rgba(216, 216, 216, 0.15)",
        },
        "[class*='MuiFormLabel-asterisk']": {
          color: "#e74d3d",
        },
        ".capitalize": {
          textTransform: "capitalize",
        },
        ".upperCase": {
          textTransform: "uppercase",
        },
        ".truncate": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        ".hidden-in-print": {
          "@media print": {
            display: "none",
          },
        },
        "button": {
          textDecoration: "none",
          color: theme.palette.primary.main,
          "@media print": {
            display: "none !important",
          },
        },
        "a": {
          color: theme.palette.common.black,
          fontWeight: "700",
          "@media print": {
            display: "none !important",
          },
        },
        footer: {
          "@media print": {
            display: "none",
          },
        },
        // Table styles
        "[class*='MuiTableContainer-root']": {
          boxShadow:
            "0px 1px 0px -1px rgba(0,0,0,0.1), 0px 0px 1px 0px rgba(0,0,0,0.1), 0px 0px 1px 0px rgba(0,0,0,0.1)",
          borderRadius: 0,
        },
        table: {
          "& tbody [class*='MuiTableRow-root']": {
            "&:nth-of-type(odd):not(.tfoot)": {
              backgroundColor: "#f9f9f9",
            },
          },
          "& [class*='MuiTableCell-root']": {
            padding: "0.5rem 0.625rem 0.625rem !important",
            [theme.breakpoints.up("lg")]: {
              fontSize: theme.typography.pxToRem(15),
            },
            "&.table-actions": {
              "@media print": {
                display: "none",
              },
              "&[class*='MuiTableCell-body']": {
                fontWeight: 500,
              },
            },
          },
          "& thead [class*='MuiTableCell-root']": {
            textAlign: "left",
            textTransform: "capitalize",
            fontWeight: "bold",
          },
          "& .tfoot": {
            "& > *": {
              fontWeight: 900,
            },
          },
        },
        ".tableRowCard": {
          // Table in mobile
          marginBottom: theme.typography.pxToRem(1),
          fontSize: theme.typography.pxToRem(16),
          width: "100%",
          "&:nth-of-type(even)": {
            backgroundColor: "#f9f9f9",
          },
        },
        ".tableRowCard__column": {
          "&.table-actions, & .table-actions ": {
            fontWeight: 500,
            "@media print": {
              display: "none",
            },
          },
          display: "flex",
          justifyContent: "space-between",
          padding: theme.spacing(2),
          borderBottom: `1px solid ${theme.palette.grey[100]}`,
          "&.total": {
            justifyContent: "center",
            "& > *": {
              fontWeight: 900,
            },
          },
          "& .tableRowCard__column-name": {
            fontWeight: 600,
          },
        },

        "& .overFlowXHidden": {
          overflowX: "hidden",
        },
        "& .g-recaptcha": {
          [theme.breakpoints.down("md")]: {
            transform: "scale(.7)",
            transformOrigin: "0 0",
          },
        },

        // Payment History print Table styles
        "& .PHTable": {
          "@media print": {
            "& .MuiTableCell-root": {
              padding: `${theme.spacing(1)} !important`,
              color: theme.palette.grey[300],
              fontSize: theme.typography.pxToRem(14),
              fontWeight: 400,
              backgroundColor: theme.palette.common.white,
              border: 0,
            },
            "& .MuiTableRow-root": {
              borderBottom: `1px solid ${theme.palette.grey[50]}`,
              "&:last-child": {
                borderBottom: 0,
              },
            },
            "& thead [class*='MuiTableCell-root']": {
              textAlign: "left",
              textTransform: "capitalize",
              fontWeight: "bold",
            },
            "& .PaymentHistory-green": {
              color: theme.palette.success.main,
            },
            "&.MuiPaper-root": {
              borderRadius: 0,
              boxShadow: "unset",
              borderBottom: `1px solid ${theme.palette.grey[50]}`,
              padding: theme.spacing(0, 2, 2),
              marginBottom: theme.spacing(2),
              "&:last-child": {
                paddingBottom: 0,
                marginBottom: 0,
              },
              "& .tableRowCard__column": {
                borderBottom: 0,
                padding: theme.spacing(0.5),
                "& .tableRowCard__column-name": {
                  fontWeight: 500,
                },
                "&.total": {
                  justifyContent: "end",
                },
              },
            },
          },
        },
      },
    },
  },
});

const GlobalStyles = () => (
  <ThemeProvider theme={GlobalTheme}>
    <CssBaseline />
  </ThemeProvider>
);

export { GlobalStyles };
