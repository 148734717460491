import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(0.75, 2),
    borderRadius: theme.spacing(0.5),
    minWidth: "unset",
    "& .MuiButton-startIcon": {
      margin: 0,
      "& .MuiSvgIcon-root": {
        fontSize: theme.spacing(3.0625),
      },
    },
    "&.ARBIconBtn": {
      backgroundColor: theme.palette.grey.A000,
      width: "fit-content",
    },
  },
}));

export default StyledButton;
