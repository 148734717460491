import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import { notifyAirbrake } from "lib/config/airbrake";
import { setError, setSnackbar } from "reduxStore/features/ui-slice";
import PATHS from "lib/navigation/paths";
import useAppNavigator from "views/common/hooks/useAppNavigator";

const useErrorsHandler = () => {
  const dispatch = useDispatch();
  const appNavigator = useAppNavigator();
  const { t } = useTranslation();

  const inPlaceCheck = (inPlace, statusCode = 500, msg = "") => {
    if (inPlace) {
      const message =
        msg ||
        t(
          `common_layout:errors.error_${
            statusCode != 400 ? statusCode : 500
          }.description`
        );
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message,
        })
      );
    } else {
      dispatch(setError({ statusCode }));
    }
  };
  const handleErrorResponse = (err, inPlace = true) => {
    if (err.response) {
      try {
        switch (err.response.status) {
          case 401:
            if (inPlace) {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: "error",
                  message: t("common_layout:errors.error_401"),
                })
              );
            } else {
              appNavigator.push(
                PATHS.signIn({ return_url: appNavigator.asPath })
              );
            }
            break;
          case 403:
            if (inPlace) {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: "error",
                  message: t("common_layout:errors.error_403"),
                })
              );
            } else {
              appNavigator.push(PATHS.home());
            }
            break;
          case 400:
            notifyAirbrake(err.response);
            inPlaceCheck(inPlace, 500, err.response?.data?.error_message);
            break;
          case 404:
            inPlaceCheck(inPlace, 404, err.response?.data?.error_message);
            break;
          default:
            inPlaceCheck(inPlace, 500, err.response?.data?.error_message);
        }
      } catch (e) {
        notifyAirbrake(err.response);
        inPlaceCheck(inPlace, 500);
      }
    } else if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      inPlaceCheck(inPlace, 500);
    } else {
      // Something happened in setting up the request that triggered an Error
      notifyAirbrake(err);
      inPlaceCheck(inPlace, 500);
    }
  };

  return handleErrorResponse;
};
export { useErrorsHandler };
