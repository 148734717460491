/* eslint-disable mui-unused-classes/unused-classes */
import React from "react";
import Image from "next/image";
import Backdrop from "@mui/material/Backdrop";
import { styled, keyframes } from "@mui/material/styles";
import { useSelector } from "react-redux";
import erdxLogo from "public/images/spinner-logo.png";

const spinningLoader = keyframes`
    0% {
    transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;
const StyledBackDrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
}));
const LogoWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  width: theme.typography.pxToRem(130),
  height: theme.typography.pxToRem(130),
}));
const Logo = styled("div")(({ theme }) => ({
  left: "50%",
  top: "50%",
  transform: "translate(-50%,-50%)",
  // width: theme.typography.pxToRem(80),
  display: "flex",
  position: "absolute",
  "& img": {
    width: "100%",
    height: "100%",
  },
}));
const LoaderBorder = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  borderTop: "0.2em solid rgba(255,255,255,0.2)",
  borderRight: "0.2em solid rgba(255,255,255,0.2)",
  borderBottom: "0.2em solid rgba(255,255,255,0.2)",
  borderLeft: "0.2em solid rgba(255,255,255,0.75)",
  animation: `${spinningLoader} 1.1s infinite linear`,
}));

const LoadingSpinner = () => {
  const loadingSpinner = useSelector((state) => state.ui.loadingSpinner);
  return (
    <StyledBackDrop open={loadingSpinner}>
      <LogoWrapper>
        <Logo>
          <Image src={erdxLogo} width={60} alt="EagleRider" />
        </Logo>
        <LoaderBorder />
      </LogoWrapper>
    </StyledBackDrop>
  );
};

export { LoadingSpinner };
