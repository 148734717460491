import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  IconButton,
  useTheme,
  useMediaQuery,
  Typography,
  TextField,
  Box,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogActions,
  Button,
} from "@mui/material";
import StyledDialog from "views/common/components/UI/TopBar/TopBarDialogStyle/TopBarDialogStyle.styles";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "next-i18next";
import { Typography500 } from "views/common/components/UI/Typography500";
import { DealersListDialog } from "views/common/components/UI/TopBar";
import axios, { API } from "lib/config/axios";
import { setLoading } from "reduxStore/features/ui-slice";
import { useDispatch } from "react-redux";
import { useErrorsHandler } from "views/common/hooks";

const DealerDialog = (props) => {
  const { handleClose, open } = props;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { t } = useTranslation();
  const handleErrorResponse = useErrorsHandler();
  const dispatch = useDispatch();
  //state
  const [dealerTypes, setDealerTypes] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [dealerState, setDealerState] = useState({
    dealers: [],
    isDealerListDialogOpen: false,
  });

  //handler
  const handleFormInputsChange = (event) => {
    const { value, name } = event.target;
    setSearchQuery((prevState) => ({
      ...prevState,
      [name]: value === "" ? null : value,
    }));
  };

  const handleSearchBtnClick = () => {
    dispatch(setLoading(true));
    axios
      .get(API.dealers, { params: searchQuery })
      .then((res) => {
        setDealerState({
          dealers: res.data.dealers,
          isDealerListDialogOpen: true,
        });
      })
      .catch((err) => {
        handleErrorResponse(err, false);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleDealersListDialogClose = () => {
    setDealerState((prevState) => ({
      ...prevState,
      isDealerListDialogOpen: false,
    }));
    handleClose();
  };

  const getDealerTypes = () => {
    dispatch(setLoading(true));
    axios
      .get(API.dealerTypes)
      .then((res) => {
        setDealerTypes(res.data);
      })
      .catch((err) => {
        handleErrorResponse(err, false);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const restrictToNumericInput = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    getDealerTypes();
  }, []);
  return (
    <>
      <StyledDialog
        open={open}
        maxWidth="md"
        onClose={() => {
          setSearchQuery(null);
          handleClose();
        }}
        fullScreen={smallScreen}
      >
        <DialogTitle className="TBDialogTitle">
          {t("common_layout:overlays.find_select_dealer")}
          <IconButton className="TBCloseIcon" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="TBDialogContent">
          <Box mb={3}>
            <Typography500 variant="h4" mb={1}>
              {t("common_layout:overlays.dealer_identification")}
            </Typography500>
            <Stack
              direction={{ sm: "column", md: "row" }}
              alignItems="center"
              spacing={2}
              mt={0.5}
            >
              <TextField
                fullWidth
                name="number"
                label={t("common_layout:overlays.dealer_number")}
                variant="outlined"
                onChange={handleFormInputsChange}
                onKeyPress={restrictToNumericInput}
              />
              <Box className="OrBox">
                <Typography className="OrText" component="span">
                  {t("common_layout:overlays.or")}
                </Typography>
              </Box>
              <TextField
                fullWidth
                name="name"
                label={t("common_layout:overlays.dealer_name")}
                variant="outlined"
                onChange={handleFormInputsChange}
              />
            </Stack>
          </Box>
          <Box mb={3} className="OrBox">
            <Typography className="OrText" component="span">
              {t("common_layout:overlays.or")}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography500 variant="h4" mb={1}>
              {t("common_layout:overlays.dealer_details")}
            </Typography500>
            <Stack
              direction={{ sm: "column", md: "row" }}
              alignItems="center"
              spacing={{ sm: 2, md: 3 }}
              mt={0.5}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("common_layout:overlays.select_type")}
                </InputLabel>
                <Select
                  label="Select Type"
                  name="dealer_type_id"
                  defaultValue=""
                  onChange={handleFormInputsChange}
                >
                  <MenuItem value="">
                    {t("common_layout:overlays.select_type")}
                  </MenuItem>
                  {dealerTypes?.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions className="TBDialogActions">
          <Button variant="contained" onClick={handleSearchBtnClick}>
            {t("common_layout:overlays.search")}
          </Button>
        </DialogActions>
      </StyledDialog>

      {/* DealersListDialog */}
      <DealersListDialog
        open={dealerState.isDealerListDialogOpen}
        handleClose={handleDealersListDialogClose}
        dealers={dealerState.dealers || []}
      />
    </>
  );
};

export { DealerDialog };
