import React, { useState, useMemo, useEffect } from "react";
import { Typography, Stack, Button, Box, IconButton } from "@mui/material";
import { useTranslation } from "next-i18next";
import StyledBox from "views/common/components/UI/CardActions/CardActions.styles";
import { Typography700 } from "views/common/components/UI";
import { useSelector } from "react-redux";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useTheme, useMediaQuery } from "@mui/material";
import dinero from "lib/utils/dinero";
import formatMoney from "lib/utils/formatMoney";
import NavigatorLink from "views/common/components/Logical/NavigatorLink";
import PATHS from "lib/navigation/paths";
import { BuyNowBtn, AddAndRemoveCartBtn } from "views/common/components/UI";

const CardActions = (props) => {
  const { t } = useTranslation("common_card_actions");
  const {
    vehicle,
    salesContext,
    availableCheckOutOptions,
    handleCustomAgreeClickBtn,
    isCartView = false,
    showFixedBarOnly = false,
  } = props;
  const theme = useTheme();
  const isMediumOrSmallScreen = useMediaQuery(theme.breakpoints.down("lg")); // Detect if screen is medium or smaller
  const appState = useSelector((state) => state.globalData.appState);
  const currentDealer = appState.currentDealer;
  const activeOrderId = appState.activeOrderId;
  const orderPrice = appState.orderPrice || vehicle?.price;
  const cartSize = appState.cartSize;
  const showPrice = salesContext?.show_price_panel;
  const [availableForBooking, setAvailableForBooking] = useState(
    salesContext?.available_for_booking
  );
  const [inCart, setInCart] = useState(salesContext?.in_cart);
  const [totalOrderPrice, setTotalOrderPrice] = useState(vehicle.price);
  const formattedPrices = useMemo(
    () => ({
      vehiclePrice: formatMoney(
        dinero(isCartView ? totalOrderPrice : vehicle?.price)
      ),
      nadaRoughPrice: formatMoney(dinero(vehicle?.nada_rough_price)),
      nadaCleanPrice: formatMoney(dinero(vehicle?.nada_clean_price)),
    }),

    [vehicle, totalOrderPrice]
  );
  const toggleInCartState = () => {
    setInCart(!inCart);
  };

  useEffect(() => {
    salesContext?.available_for_booking &&
      setAvailableForBooking(currentDealer !== null);
  }, [currentDealer]);

  // Initially, the total price is set to the price received from the response, but when an item is removed, the total price changes.
  useEffect(() => {
    if (orderPrice) {
      setTotalOrderPrice(orderPrice);
    }
  }, [orderPrice]);

  return (
    <>
      {/* If showFixedBarOnly is true and screen size is medium or smaller, render only the FixedBar */}
      {showFixedBarOnly && isMediumOrSmallScreen && (
        <>
          <StyledBox>
            <Stack className="CAFixedBar">
              {(showPrice || isCartView) && (
                <Box>
                  <Typography variant="body2">
                    {t("common_card_actions:our_price")}
                  </Typography>
                  <Typography700 variant="h3" component="p">
                    {formattedPrices.vehiclePrice}
                  </Typography700>
                </Box>
              )}
              {(availableForBooking || isCartView) && (
                <Box className="CAFixedBarActions">
                  <BuyNowBtn
                    fixedBar
                    availableCheckOutOptions={availableCheckOutOptions}
                    vehicleId={vehicle.id}
                    showCheckOutText={isCartView}
                    handleCustomAgreeClickBtn={handleCustomAgreeClickBtn}
                  />

                  {!isCartView && (
                    <AddAndRemoveCartBtn
                      fixedBar
                      inCart={inCart}
                      toggleInCartState={toggleInCartState}
                      activeOrderId={activeOrderId}
                      vehicleId={vehicle.id}
                    />
                  )}
                </Box>
              )}
            </Stack>
          </StyledBox>
        </>
      )}
      <StyledBox>
        {(showPrice || isCartView) && (
          <Stack className="CAHeader">
            <Typography variant="body2" color="secondary.light">
              {t("common_card_actions:our_price")}
            </Typography>
            <Typography700 variant="h3" component="p">
              {formattedPrices.vehiclePrice}
            </Typography700>
          </Stack>
        )}
        <Stack className="CAContent">
          {isCartView ? (
            <Typography variant="body1" fontWeight="bold">
              {t("common_card_actions:motorcycles_in_cart", {
                value: cartSize,
              })}
            </Typography>
          ) : (
            showPrice && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body1">
                    {t("common_card_actions:rough_price")}
                  </Typography>
                  <Typography variant="body1">
                    {formattedPrices.nadaRoughPrice}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body1">
                    {t("common_card_actions:clean_price")}
                  </Typography>
                  <Typography variant="body1">
                    {formattedPrices.nadaCleanPrice}
                  </Typography>
                </Stack>
              </>
            )
          )}

          <Box>
            {(availableForBooking || isCartView) && (
              <>
                <Box my={1.5}>
                  <BuyNowBtn
                    availableCheckOutOptions={availableCheckOutOptions}
                    vehicleId={vehicle.id}
                    showCheckOutText={isCartView}
                    handleCustomAgreeClickBtn={handleCustomAgreeClickBtn}
                  />
                </Box>
                {!isCartView && (
                  <AddAndRemoveCartBtn
                    inCart={inCart}
                    toggleInCartState={toggleInCartState}
                    activeOrderId={activeOrderId}
                    vehicleId={vehicle.id}
                  />
                )}
              </>
            )}
            <Box mt={3}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                startIcon={<ArrowBackOutlinedIcon />}
                component={NavigatorLink}
                href={PATHS.vehicles.index()}
              >
                {t("common_card_actions:back_to_inventory")}
              </Button>
            </Box>
          </Box>
        </Stack>
      </StyledBox>
    </>
  );
};

export { CardActions };
