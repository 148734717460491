import React, { useState } from "react";
import { MenuItemSelect } from "views/common/components/UI/TopBar";
import { useTranslation } from "next-i18next";
import { useMenuOptions } from "views/common/hooks";
import { ACTIONS, LOCAL_STORAGE_ITEMS } from "views/common/utils/constants";
import Dealer from "public/Icons/Dealer";
import { useDispatch, useSelector } from "react-redux";
import { setAppData } from "reduxStore/features/globalData-slice";
import dynamic from "next/dynamic";

const DealerDialog = dynamic(() =>
  import("views/common/components/UI/TopBar/DealerDialog").then(
    (mod) => mod.DealerDialog
  )
);
const DealerChangeButton = (props) => {
  const { handleCloseDrawer } = props;
  const { t } = useTranslation("common_layout");
  const appState = useSelector((state) => state.globalData.appState);
  const dispatch = useDispatch();
  const { dealerMenuOptions } = useMenuOptions();
  const [openDealerDialog, setOpenDealerDialog] = useState(false);
  const toggleDealerDialogOpenState = () => {
    setOpenDealerDialog(!openDealerDialog);
  };

  const handleOverlayActions = (option) => {
    const { action } = option;
    if (action === ACTIONS.select || action === ACTIONS.change)
      toggleDealerDialogOpenState();

    if (action === ACTIONS.remove) {
      dispatch(
        setAppData({
          ...appState,
          currentDealer: null,
          cartSize: null,
          activeOrderId: null,
          orderPrice: null,
        })
      );
      localStorage.removeItem(LOCAL_STORAGE_ITEMS.current_Dealer);
      localStorage.removeItem(LOCAL_STORAGE_ITEMS.cart_Size);
      localStorage.removeItem(LOCAL_STORAGE_ITEMS.active_Order_Id);
      localStorage.removeItem(LOCAL_STORAGE_ITEMS.order_Price);
    }
  };

  return (
    <>
      {dealerMenuOptions?.length > 0 && (
        <MenuItemSelect
          label={t("common_layout:dealer")}
          icon={<Dealer />}
          options={dealerMenuOptions}
          value={0} //the component reads the index as a value
          handleChange={(option) => handleOverlayActions(option)}
          disabled={dealerMenuOptions.length === 1}
          dark
        />
      )}
      {openDealerDialog && (
        <DealerDialog
          open={openDealerDialog}
          handleClose={toggleDealerDialogOpenState}
        />
      )}
    </>
  );
};

export { DealerChangeButton };
