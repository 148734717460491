import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Roboto } from "next/font/google";
import theme from "lib/config/theme";
import { GlobalStyles } from "styles/GlobalStyles";
import { Box } from "@mui/material";
import { appWithTranslation } from "next-i18next";
import { store } from "reduxStore/app/store";
import { Provider } from "react-redux";
import { Layout } from "views/common/components/Logical/Layout";
import { initAirbrake } from "lib/config/airbrake";
import LayoutLogicWrapper from "views/common/components/HOC/LayoutLogicWrapper";
import Head from "next/head";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
});

initAirbrake();

const App = ({ Component, pageProps }) => (
  <Box className={roboto.className}>
    <div className="pageLayout">
      <Head>
        <title>ERDX</title>
         {/* Default favicon */}
         <link rel="icon" href="/erdx/images/favicon-dark.ico" />
           {/* Favicon for light mode */}
           <link
              rel="icon"
              type="image/x-icon"
              href="/erdx/images/favicon-dark.ico"
              media="(prefers-color-scheme: light)"
            />

              {/* Favicon for dark mode */}
            <link
              rel="icon"
              type="image/x-icon"
              href="/erdx/images/favicon-light.ico"
              media="(prefers-color-scheme: dark)"
            />

          

        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="The world&#39;s largest motorcycle rental and tour company with rentals starting at $29/day and bike + hotel tour packages starting at $135/day. "
        />
      </Head>
      <Provider store={store}>
        <LayoutLogicWrapper {...pageProps}>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Layout {...pageProps}>
              <Component {...pageProps} />
            </Layout>
          </ThemeProvider>
        </LayoutLogicWrapper>
      </Provider>
    </div>
  </Box>
);

export default appWithTranslation(App);
