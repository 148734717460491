import React, { useMemo } from "react";
import {
  DialogTitle,
  DialogContent,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import StyledDialog from "views/common/components/UI/TopBar/TopBarDialogStyle/TopBarDialogStyle.styles";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "next-i18next";
import axios, { API } from "lib/config/axios";
import { setLoading } from "reduxStore/features/ui-slice";
import { useDispatch, useSelector } from "react-redux";
import { useErrorsHandler } from "views/common/hooks";
import { setAppData } from "reduxStore/features/globalData-slice";
import { LOCAL_STORAGE_ITEMS } from "views/common/utils/constants";
import { CommonTable } from "views/common/components/UI";

const DealersListDialog = (props) => {
  const { handleClose, open, dealers } = props;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { t } = useTranslation();
  const appState = useSelector((state) => state.globalData.appState);
  const handleErrorResponse = useErrorsHandler();
  const dispatch = useDispatch();

  const handleSelectBtnClick = (dealer) => {
    const { id, name } = dealer;

    // Update the app state with the selected dealer
    const newAppState = {
      ...appState,
      currentDealer: { id, name },
    };

    dispatch(setAppData(newAppState));

    // Save the current dealer to localStorage
    localStorage.setItem(
      LOCAL_STORAGE_ITEMS.current_Dealer,
      JSON.stringify({ id, name })
    );

    dispatch(setLoading(true));

    const endpoints = [
      API.cart,
      API.orders.activeOrder.replace("{dealer_id}", id),
    ];

    const fetchEndpoints = endpoints.map((endpoint) => axios.get(endpoint));
    Promise.all(fetchEndpoints)
      .then((responses) => {
        const [cartResponse, orderResponse] = responses;
        const cartSize = cartResponse.data.cart?.items_count || 0;
        const activeOrderId = orderResponse.data?.id || null;

        //  Update the app state with the cart size and active order ID
        dispatch(
          setAppData({
            ...newAppState,
            cartSize: cartSize,
            activeOrderId: activeOrderId,
          })
        );

        // Save the cart size and active order ID to localStorage
        localStorage.setItem(LOCAL_STORAGE_ITEMS.cart_Size, cartSize);
        localStorage.setItem(
          LOCAL_STORAGE_ITEMS.active_Order_Id,
          activeOrderId
        );

        handleClose();
      })
      .catch((err) => {
        handleErrorResponse(err, false);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const tableHeadItems = [
    t("common_layout:overlays.dealer_name"),
    t("common_layout:overlays.dealer_number"),
    t("common_layout:overlays.dealer_type"),
    "",
  ];

  const dealersList = useMemo(() => {
    return dealers.map((dealer) => {
      return [
        { text: dealer.name, type: "text" },
        { text: dealer.number, type: "text" },
        { text: dealer.type, type: "text" },
        {
          text: t("common_layout:overlays.select"),
          type: "button",
          variant: "contained",
          cb: () => handleSelectBtnClick(dealer),
          columnAlignRight: true,
        },
      ];
    });
  }, [dealers]);

  return (
    <StyledDialog
      open={open}
      maxWidth="lg"
      onClose={handleClose}
      fullScreen={smallScreen}
    >
      <DialogTitle className="TBDialogTitle">
        {t("common_layout:overlays.find_select_dealer")}
        <IconButton className="TBCloseIcon" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="TBDialogContent">
        <CommonTable tableHeadItems={tableHeadItems} tableData={dealersList} />
      </DialogContent>
    </StyledDialog>
  );
};

export { DealersListDialog };
