// hooks/useMenuOptions.js
import { useTranslation } from "next-i18next";
import { ACTIONS } from "views/common/utils/constants";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const useMenuOptions = () => {
  const { t } = useTranslation("common_layout");
  const appState = useSelector((state) => state.globalData.appState);
  const currentDealer = appState.currentDealer;
  const currentUser = appState.currentUser;

  const dealerMenuOptions = useMemo(() => {
    const dealerMenuOptions = [];
    if (currentDealer) {
      dealerMenuOptions.push({
        label: currentDealer.name,
        action: null,
        disabled: true,
      });

      if (currentUser?.dealers_count !== 1) {
        dealerMenuOptions.push(
          { label: t("common_layout:change_dealer"), action: ACTIONS.change },
          { label: t("common_layout:remove_dealer"), action: ACTIONS.remove }
        );
      }
    } else if (currentUser) {
      dealerMenuOptions.push({
        label: t("common_layout:no_selected_dealer"),
        action: null,
        disabled: true,
      });
      if (currentUser?.dealers_count !== 1) {
        dealerMenuOptions.push({
          label: t("common_layout:select_dealer"),
          action: ACTIONS.select,
        });
      }
    }
    return dealerMenuOptions;
  }, [currentDealer, currentUser]);

  return { dealerMenuOptions };
};

export { useMenuOptions };
