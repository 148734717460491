import { FormControl, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  backgroundColor: theme.palette.grey[700],
  minWidth: theme.spacing(15),
  borderRadius: 4,
  display: "flex",
  flexGrow: 1,
  padding: theme.spacing(0),
  minHeight: theme.spacing(6),
  minWidth: theme.spacing(25),
  "& .MISIcon": {
    marginRight: theme.spacing(1),
    "& svg": {
      fontSize: theme.typography.pxToRem(16),
    },
    "@media only screen and (max-width: 1024px)": {
      marginRight: theme.spacing(0.5),
      "& svg": {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
  "& .MISLabel": {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    padding: theme.spacing(0, 1),
    top: theme.spacing(0.5),
    transform: "unset",
    "&.Mui-focused": { color: theme.palette.common.white },
  },

  "& .MISSelect": {
    color: theme.palette.grey[300],
    fontSize: theme.typography.pxToRem(12),
    flex: 1,
    "& .MuiSelect-select": {
      padding: theme.spacing(3, 3, 0, 4),
      lineHeight: "normal",
      color: theme.palette.common.white,
      "&::after, ::before": {
        borderColor: "transparent !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "unset",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.common.white,
      top: "calc(50% - 2px)",
      "&.Mui-disabled": {
        display: "none",
      },
    },
    "& .Mui-disabled": {
      background: "transparent",
      color: theme.palette.grey[200],
      WebkitTextFillColor: theme.palette.grey[200],
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme, bordered }) => ({
  "&.MISMenuItem": {
    padding: theme.spacing(1.5, 2),
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    whiteSpace: "normal",
    lineHeight: theme.spacing(2.5),
    borderBottom:
      bordered === "true" ? `1px solid ${theme.palette.grey[100]}` : null,
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
    "&.Mui-disabled": {
      display: "none",
    },
  },
}));

export default StyledFormControl;
