/* eslint-disable no-alert */
import { useEffect } from "react";
import axios, { API } from "lib/config/axios";
import { useDispatch, useSelector } from "react-redux";
import useAppNavigator from "views/common/hooks/useAppNavigator";
import { useErrorsHandler } from "views/common/hooks";

import {
  setAppData,
  resetGlobalDataSlice,
} from "reduxStore/features/globalData-slice";
import {
  setError,
  setLoading,
  setShouldRenderPage,
  resetUISlice,
} from "reduxStore/features/ui-slice";
import { LOCAL_STORAGE_ITEMS } from "views/common/utils/constants";

const LayoutLogicWrapper = (props) => {
  const { layoutData, children } = props;

  const dispatch = useDispatch();

  const handleErrorResponse = useErrorsHandler();
  const error = useSelector((state) => state.ui.error);
  const suppressNavigationLoader = useSelector(
    (state) => state.ui.suppressNavigationLoader
  );
  const appNavigator = useAppNavigator();
  const addLangAttribute = () => {
    document.querySelector("html").setAttribute("lang", appNavigator.locale);
  };

  const initReduxStore = () => {
    dispatch(resetGlobalDataSlice());
    dispatch(resetUISlice());
  };
  if (typeof window === "undefined") {
    initReduxStore();
  }

  const fetchActiveOrderAndCartSize = (layoutData) => {
    const { id, name } = layoutData.currentDealer;

    // Update the app state with the selected dealer
    const newAppState = {
      ...layoutData,
      currentDealer: { id, name },
    };

    dispatch(setAppData(newAppState));

    // Save the current dealer to localStorage
    localStorage.setItem(
      LOCAL_STORAGE_ITEMS.current_Dealer,
      JSON.stringify({ id, name })
    );

    dispatch(setLoading(true));

    const endpoints = [
      API.cart,
      API.orders.activeOrder.replace("{dealer_id}", id),
    ];

    const fetchEndpoints = endpoints.map((endpoint) => axios.get(endpoint));
    Promise.all(fetchEndpoints)
      .then((responses) => {
        const [cartResponse, orderResponse] = responses;
        const cartSize = cartResponse.data.cart?.items_count || 0;
        const activeOrderId = orderResponse.data?.id || null;

        //  Update the app state with the cart size and active order ID
        dispatch(
          setAppData({
            ...newAppState,
            cartSize: cartSize,
            activeOrderId: activeOrderId,
          })
        );

        // Save the cart size and active order ID to localStorage
        localStorage.setItem(LOCAL_STORAGE_ITEMS.cart_Size, cartSize);
        localStorage.setItem(
          LOCAL_STORAGE_ITEMS.active_Order_Id,
          activeOrderId
        );
      })
      .catch((err) => {
        handleErrorResponse(err, false);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const fetchLayoutData = () => {
    Promise.all([axios.get(API.me), axios.get(API.authenticityToken)])
      .then(([userResponse, authResponse]) => {
        const combinedData = {
          currentUser: userResponse.data.current_user,
          authenticityToken: authResponse.data.authenticity_token,
        };

        // set currentDealer and cart in the appstate if they exist in the localstorage
        const currentDealer = localStorage.getItem(
          LOCAL_STORAGE_ITEMS.current_Dealer
        );

        const cartSize = localStorage.getItem(LOCAL_STORAGE_ITEMS.cart_Size);
        const activeOrderId = localStorage.getItem(
          LOCAL_STORAGE_ITEMS.active_Order_Id
        );
        if (currentDealer || combinedData?.currentUser?.current_dealer) {
          combinedData.currentDealer =
            JSON.parse(currentDealer) ||
            combinedData.currentUser.current_dealer;
        }
        if (cartSize) {
          combinedData.cartSize = JSON.parse(cartSize);
        }
        if (activeOrderId) {
          combinedData.activeOrderId = JSON.parse(activeOrderId);
        }

        if (
          combinedData?.currentUser?.can_view_erdx &&
          combinedData.currentDealer &&
          (!cartSize || !activeOrderId)
        ) {
          fetchActiveOrderAndCartSize(combinedData);
        } else {
          dispatch(setAppData(combinedData));
        }
        dispatch(setShouldRenderPage(true));
      })
      .catch((err) => {
        handleErrorResponse(err, false);
      });
  };

  useEffect(() => {
    if (!layoutData) {
      fetchLayoutData();
    }
  }, []);

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      alert(
        "Cookies are disabled!\nPlease enable cookies from your browser settings for best experience"
      );
    }
  }, []);

  // render LoadingSpinner and adjust lang attribute when navigating between pages
  useEffect(() => {
    const handlePageChangeSpinner = () => {
      if (error.statusCode) {
        dispatch(setError({ statusCode: false }));
      }
      addLangAttribute();
      if (!suppressNavigationLoader) {
        dispatch(setLoading(true));
      } else {
        dispatch(setLoading(false));
      }
    };

    const handleRouteChangeComplete = () => {
      addLangAttribute();
      if (!suppressNavigationLoader) dispatch(setLoading(false));
    };
    appNavigator.events.on("routeChangeStart", handlePageChangeSpinner);
    appNavigator.events.on("routeChangeComplete", handleRouteChangeComplete);
    appNavigator.events.on("routeChangeError", () => {
      addLangAttribute();
      dispatch(setLoading(false));
    });
    return () => {
      appNavigator.events.off("routeChangeStart", handlePageChangeSpinner);
      appNavigator.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [error, suppressNavigationLoader]);
  // adjust lang attribute when changing locales
  useEffect(() => {
    addLangAttribute();
  }, [appNavigator.locale]);

  return children;
};

export default LayoutLogicWrapper;
