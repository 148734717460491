import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1),
  color: theme.palette.common.black,
  boxShadow: "1px 2px 4px 0px rgba(0, 0, 0, 0.24)",
  [theme.breakpoints.up("lg")]: {
    position: "sticky",
    zIndex: 1,
    top: theme.spacing(2),
  },

  "& .CAHeader": {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  "& .CAContent": {
    padding: theme.spacing(3),
    [theme.breakpoints.down("xl")]: {
      padding: theme.spacing(2),
    },
  },
  "& .CAFixedBar": {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: 2,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(1, 3),
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 2),
    },
    "& .CAFixedBarActions": {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
  },
}));

export default StyledBox;
