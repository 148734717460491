import React, { useState } from "react";
import {
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "next-i18next";
import { CommonOverlayLayout, Typography500 } from "views/common/components/UI";
import NavigatorLink from "views/common/components/Logical/NavigatorLink";
import StyledBox from "views/common/components/UI/PaymentOptionDialog/PaymentOptionDialog.styles";
import PATHS from "lib/navigation/paths";
import { useDispatch, useSelector } from "react-redux";
import { useErrorsHandler } from "views/common/hooks";
import { setLoading, setSnackbar } from "reduxStore/features/ui-slice";
import axios, { API } from "lib/config/axios";
import useAppNavigator from "views/common/hooks/useAppNavigator";

const PaymentOptionDialog = (props) => {
  // props
  const {
    open,
    handleClose,
    availableCheckOutOptions,
    vehicleId,
    handleCustomAgreeClickBtn,
  } = props;

  // init
  const { t } = useTranslation("payment_option_dialog_common");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const appState = useSelector((state) => state.globalData.appState);
  const authenticityToken = appState.authenticityToken;
  const handleErrorResponse = useErrorsHandler();
  const dispatch = useDispatch();
  const appNavigator = useAppNavigator();
  const [selectedOption, setSelectedOption] = useState(
    availableCheckOutOptions.at(-1)?.id || []
  );

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleAgreeClickBtn = () => {
    if (typeof handleCustomAgreeClickBtn === "function") {
      handleCustomAgreeClickBtn(selectedOption);
    } else {
      dispatch(setLoading(true));
      const url = API.orders.index;
      const payload = {
        authenticity_token: authenticityToken,
        vehicle_id: vehicleId,
        financing_method_id: selectedOption ? Number(selectedOption) : null,
      };

      axios
        .post(url, payload)
        .then(() => {
          appNavigator.push(PATHS.orders.index());
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 406:
                if (error.response.data.errors) {
                  dispatch(
                    setSnackbar({
                      open: true,
                      severity: "error",
                      message: error.response.data.errors.join(", "),
                    })
                  );
                }
                break;
              default:
                handleErrorResponse(error);
            }
          } else {
            handleErrorResponse(error);
          }
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
  };

  return (
    <CommonOverlayLayout
      open={open}
      fullScreen={isSmallScreen}
      handleClose={handleClose}
      maxWidth="md"
      title={t("payment_option_dialog_common:terms_conditions")}
      dialogActions={
        <Button variant="contained" onClick={handleAgreeClickBtn}>
          {t("payment_option_dialog_common:i_agree")}
        </Button>
      }
    >
      <StyledBox>
        <Typography500
          variant="body1"
          component="p"
          color="secondary.main"
          mb={1}
        >
          {t("payment_option_dialog_common:how_would_you_like_to_pay")}
        </Typography500>

        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
          {availableCheckOutOptions.map((option) => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              control={<Radio />}
              label={option.name}
            />
          ))}
        </RadioGroup>

        <Typography variant="body1" mt={3}>
          {t("payment_option_dialog_common:by_clicking_agree")}
          <NavigatorLink
            href={PATHS.termsAndConditions()}
            target="_blank"
            className="PODLink"
          >
            {" "}
            {t("payment_option_dialog_common:the_erdx_terms_conditions")}
          </NavigatorLink>
        </Typography>
      </StyledBox>
    </CommonOverlayLayout>
  );
};

export { PaymentOptionDialog };
