import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    // minHeight: theme.spacing(60),
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    '& .TBDialogTitle': {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.spacing(5),
      padding: theme.spacing(3),
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.typography.pxToRem(20),
      },
      '& .TBCloseIcon': {
        position: 'absolute',
        right: theme.spacing(3),
      },
    },
    '& .TBDialogContent': {
      '& .OrBox': {
        position: 'relative',
        textTransform: 'uppercase',
        color: theme.palette.grey[300],
        minWidth: theme.spacing(7),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(14),

        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
        '&:after': {
          position: 'absolute',
          content: '""',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '100%',
          height: 1,
          backgroundColor: theme.palette.grey[100],
          right: 0,
          left: 0,
        },
        '& .OrText': {
          fontSize: theme.typography.pxToRem(12),
          backgroundColor: theme.palette.common.white,
          position: 'relative',
          zIndex: 1,
          padding: theme.spacing(0, 0.5),
        },
      },
      '& .MuiInputBase-root': {
        borderRadius: 0,
      },
    },
    '& .TBDialogActions': {
      padding: theme.spacing(0, 3, 3),
    },
  },
}));

export default StyledDialog;
