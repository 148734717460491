import React from "react";

import {
  DialogTitle,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
  DialogActions,
  Box,
} from "@mui/material";
import StyledDialog from "views/common/components/UI/CommonOverlayLayout/CommonOverlayLayout.styles";
import CloseIcon from "@mui/icons-material/Close";

const CommonOverlayLayout = (props) => {
  const theme = useTheme();

  const {
    handleClose,
    open,
    children,
    title,
    dialogActions,
    maxWidth = "lg",
    className,
    overflowVisible,
    dataTestId,
    fullScreen = useMediaQuery(theme.breakpoints.down("md")),
  } = props;

  return (
    <StyledDialog
      fullScreen={fullScreen}
      open={open}
      PaperProps={{
        "data-testid": dataTestId,
      }}
      maxWidth={maxWidth}
      onClose={handleClose}
      className={`${className} COLRoot ${
        overflowVisible && "COLVisibleOverflow"
      }`}
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton className="COLClose" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent>{children}</DialogContent>
      {dialogActions && (
        <DialogActions className="COLDialogActions">
          <Box className="COLDialogActionsWrapper">{dialogActions}</Box>
        </DialogActions>
      )}
    </StyledDialog>
  );
};

export { CommonOverlayLayout };
