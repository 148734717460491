import { configureStore } from "@reduxjs/toolkit";
import globalDataReducer from "reduxStore/features/globalData-slice";
import uiReducer from "reduxStore/features/ui-slice";

export const store = configureStore({
  reducer: {
    globalData: globalDataReducer,
    ui: uiReducer,
  },
});
