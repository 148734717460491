import { Pagination, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import StyledBox from "views/common/components/UI/CommonPagination/CommonPagination.styles";

const CommonPagination = (props) => {
  const { pagination = {}, handleChange } = props;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <StyledBox className="CPRoot">
      <Pagination
        count={pagination.total_pages}
        page={pagination.page}
        siblingCount={isTablet ? 0 : 1}
        boundaryCount={isTablet ? 1 : 2}
        onChange={handleChange}
      />
    </StyledBox>
  );
};

export { CommonPagination };
