import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .MuiRadio-root": {
    padding: theme.spacing(0.5, 1),
  },
  "& .PODLink": {
    textTransform: "uppercase",
    fontWeight: 500,
  },
}));

export default StyledBox;
