import React, { useState } from "react";
import StyledBox, {
  StyledImgBox,
} from "views/common/components/UI/VehicleCard/VehicleCard.styles";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
  Stack,
  Chip,
} from "@mui/material";
import {
  Typography500,
  Typography700,
  BuyNowBtn,
  AddAndRemoveCartBtn,
} from "views/common/components/UI";
import NavigatorLink from "views/common/components/Logical/NavigatorLink";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useTranslation } from "next-i18next";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import StarIcon from "@mui/icons-material/Star";
import Grid from "@mui/material/Grid2";
import PATHS from "lib/navigation/paths";

const VehicleCard = (props) => {
  const {
    isCartView,
    className,
    isPurchasedView,
    motorcycleData,
    handleRemoveVehicle,
    checkoutOptions,
  } = props;
  const { t } = useTranslation("common_vehicle_card");
  const [inCart, setInCart] = useState(motorcycleData.inCart);
  const toggleInCartState = () => {
    setInCart(!inCart);
  };
  const renderPricePanel = (largeScreen = true) => (
    <Box
      className={`VCPrice ${
        largeScreen ? "showLargeDESKTOP" : "showMobileAndTabletAndDesktop"
      }`}
    >
      <Typography variant="body2" className="VCLabelText">
        {t("common_vehicle_card:price")}
      </Typography>
      <Typography700 component="p" variant="h4">
        {motorcycleData.price}
      </Typography700>
    </Box>
  );

  const renderActions = (largeScreen = true) => (
    <CardActions
      className={`VCActions ${
        largeScreen ? "showLargeDESKTOP" : "showMobileAndTabletAndDesktop"
      }`}
    >
      {(isCartView || motorcycleData.availableForBooking) && (
        <>
          {!isCartView && (
            <BuyNowBtn
              vehicleId={motorcycleData.vehicleId}
              availableCheckOutOptions={checkoutOptions}
              fullWidth={false}
            />
          )}
          <AddAndRemoveCartBtn
            vehicleId={motorcycleData.vehicleId}
            handleRemoveVehicle={handleRemoveVehicle}
            toggleInCartState={toggleInCartState}
            inCart={inCart}
            fullWidth={false}
            isCartView={isCartView}
          />
        </>
      )}
      {motorcycleData.comingSoon && (
        <Typography700 component="span" variant="subtitle1" color="primary">
          {t("common_vehicle_card:coming_soon")}
        </Typography700>
      )}
      {motorcycleData.sold && (
        <Typography700 component="span" variant="subtitle1" color="primary">
          {t("common_vehicle_card:sold")}
        </Typography700>
      )}
    </CardActions>
  );
  return (
    <StyledBox className={className}>
      <Card className="VCard">
        <Grid container spacing={0}>
          <Grid size={{ xs: 12, md: 4, lg: 4 }}>
            <NavigatorLink href={PATHS.vehicles.show(motorcycleData.vehicleId)}>
              <StyledImgBox
                image={{
                  src: motorcycleData.image,
                  alt: motorcycleData.title,
                }}
                imgwidth="100%"
                imgheight="auto"
                unoptimized
              />
            </NavigatorLink>
          </Grid>
          <Grid size={{ xs: 12, md: 8, lg: 8 }}>
            <Stack>
              <CardContent>
                <Stack
                  justifyContent="space-between"
                  px={{ xs: 1, md: 2, xl: 2 }}
                  py={2}
                  flex={1}
                >
                  <Box>
                    <NavigatorLink
                      href={PATHS.vehicles.show(motorcycleData.vehicleId)}
                    >
                      <Typography500
                        component="p"
                        variant="subtitle1"
                        color="secondary.dark"
                        className="VCUnderLine"
                      >
                        {motorcycleData.title}
                      </Typography500>
                    </NavigatorLink>
                    <Stack className="VCLocationColor">
                      <Stack direction="row" spacing={1} alignItems="center">
                        <LocationOnOutlinedIcon className="VCIcon" />
                        <Typography variant="body2" className="VCLabelText">
                          {t("common_vehicle_card:location")}:
                        </Typography>
                        <Typography500 variant="body2">
                          {motorcycleData.location}
                        </Typography500>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <ColorLensOutlinedIcon className="VCIcon" />
                        <Typography variant="body2" className="VCLabelText">
                          {t("common_vehicle_card:color")}:
                        </Typography>
                        <Typography500 variant="body2">
                          {motorcycleData.color}
                        </Typography500>
                      </Stack>
                    </Stack>
                  </Box>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid item xs={12} md={6} lg={6}>
                      <Stack className="VCGrid" mb={1}>
                        <Typography variant="body2" className="VCLabelText">
                          {t("common_vehicle_card:miles")}:
                        </Typography>
                        <Box display="flex">
                          {motorcycleData.miles.map((digit, index) => (
                            <Box key={index} className="VCDigit">
                              <Typography500 variant="body1">
                                {digit}
                              </Typography500>
                            </Box>
                          ))}
                        </Box>
                      </Stack>
                      {(isPurchasedView ||
                        isCartView ||
                        motorcycleData.showExtraFields) && (
                        <Stack className="VCGrid">
                          <Typography variant="body2" className="VCLabelText">
                            {t("common_vehicle_card:vin")}:
                          </Typography>
                          <Typography500 variant="body2">
                            {motorcycleData.vin}
                          </Typography500>
                        </Stack>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Stack className="VCGrid" mb={1}>
                        <Typography variant="body2" className="VCLabelText">
                          {t("common_vehicle_card:condition_score")}:
                        </Typography>
                        <Chip
                          icon={<StarIcon className="VCChipIcon" />}
                          label={motorcycleData.conditionScore}
                          className="VCChip"
                        />
                      </Stack>
                      {(isPurchasedView ||
                        isCartView ||
                        motorcycleData.showExtraFields) && (
                        <Stack className="VCGrid">
                          <Typography variant="body2" className="VCLabelText">
                            {t("common_vehicle_card:sku")}:
                          </Typography>
                          <Typography500 variant="body2">
                            {motorcycleData.sku}
                          </Typography500>
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                </Stack>
                {(motorcycleData.showPrice || isCartView || isPurchasedView) &&
                  renderPricePanel()}
              </CardContent>

              {!isPurchasedView && renderActions()}
            </Stack>
          </Grid>
        </Grid>
        <Box className="VCActionsAndPriceDestop">
          {(motorcycleData.showPrice || isCartView || isPurchasedView) &&
            renderPricePanel(false)}
          {!isPurchasedView && renderActions(false)}
        </Box>
      </Card>
    </StyledBox>
  );
};

export { VehicleCard };
