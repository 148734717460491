import React from "react";
import { Typography, Container } from "@mui/material";
import { useTranslation } from "next-i18next";
import StyledBox from "views/common/components/UI/Footer/Footer.styles";
import Image from "next/image";
import { Box, Stack } from "@mui/material";
import PATHS from "lib/navigation/paths";
import NavigatorLink from "views/common/components/Logical/NavigatorLink";
import { Typography500 } from "views/common/components/UI";
import erdxLogo from "public/images/er-logo-erdx.png";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <StyledBox>
      <Container maxWidth="xl">
        <Box className="FWrapper">
          <Box component={NavigatorLink} href={PATHS.home()} display="flex">
            <Image
              src={erdxLogo}
              width={170}
              alt={t("common_layout:eagleRider")}
            />
          </Box>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={{ sm: 0.5, md: 4 }}
          >
            <NavigatorLink href={PATHS.termsAndConditions()} className="FLink" target="_blank">
              <Typography500 variant="body2" className="FText">
                {t("common_layout:terms_and_conditions")}
              </Typography500>
            </NavigatorLink>
            <Typography variant="body2" className="FText">
              {t("common_layout:copyright")} &copy;
              {new Date().getFullYear()} {t("common_layout:eagleRider")}.
            </Typography>
          </Stack>
        </Box>
      </Container>
    </StyledBox>
  );
};

export { Footer };
