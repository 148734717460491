import React from "react";
import StyledTypography from "views/common/components/UI/Typography500/Typography500.styles";

const Typography500 = (props) => {
  const { children, ...other } = props;

  return <StyledTypography {...other}>{children}</StyledTypography>;
};

export { Typography500 };
