/* eslint-disable */
/* eslint-disable prefer-destructuring */
import { OTHER, ERDX_BE, CURRENT_APP, WEBSITE_FE } from "lib/navigation/apps";

const { envVars } = require("next/config").default().publicRuntimeConfig;

const PATHS = {
  home: (query) => ({ url: "/", app: CURRENT_APP, query }),
  signup: (query) => ({ url: "/signup", app: CURRENT_APP, query }),
  orders: {
    index: (query) => ({
      url: "/orders",
      app: CURRENT_APP,
      query,
    }),
    orderList: (dealerId, query) => ({
      url: `/orders/${dealerId}`,
      app: CURRENT_APP,
      query,
    }),
  },
  personalInfo: (query) => ({
    url: "/user/personal_information",
    app: WEBSITE_FE,
    query,
  }),
  termsAndConditions: (query) => ({
    url: "/erdx-terms-and-conditions",
    app: CURRENT_APP,
    query,
  }),
  vehicles: {
    index: (query) => ({
      url: "/vehicles",
      app: CURRENT_APP,
      query,
    }),
    show: (id, query) => ({
      url: `/vehicles/${id}`,
      app: CURRENT_APP,
      query,
    }),
  },
  resetPassword: (query) => ({
    url: "/users/password",
    app: WEBSITE_FE,
    query,
  }),
};

export default PATHS;
