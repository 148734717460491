import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({ theme, imgwidth, imgheight, objectFit }) => ({
  "&.IBimgbox": {
    width: imgwidth || "100%",
    height: imgheight || "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  "& .IBimg": {
    objectFit: objectFit || "cover",
  },
}));
export default StyledBox;
