import { useRouter } from "next/router";
import { ERDX_BE, CURRENT_APP } from "lib/navigation/apps";

const { envVars } = require("next/config").default().publicRuntimeConfig;

const { API_PROTOCOL, EN_DOMAIN, BASE_PATH } = envVars;

const shallowClone = (obj) =>
  Object.create(
    Object.getPrototypeOf(obj),
    Object.getOwnPropertyDescriptors(obj)
  );

const useAppNavigator = () => {
  let urlObject = {};
  const router = useRouter();

  const getUrl = (locale, href) => {
    const isObject = typeof href === "object";
    const linkUrl = isObject ? href.url : href;
    const linkUrlSegments = linkUrl.split("/").slice(1);
    let link = "";
    const otherApp = (isObject && href.app !== CURRENT_APP) || !isObject;
    if (linkUrl.startsWith("http")) return linkUrl;
    if (locale) {
      if (router.locale !== locale) {
        link = `${API_PROTOCOL}://${EN_DOMAIN}${BASE_PATH}/${
          locale !== "en" ? locale : ""
        }`;
      }
    } else if (otherApp) {
      link = `${API_PROTOCOL}://${EN_DOMAIN}`;
      if (href?.app === ERDX_BE) {
        link += "/erdx";
      }
    }
    link = [link, ...linkUrlSegments].join("/");

    return link;
  };

  const appendQuery = (url, query) => {
    let queryString = "";
    if (query) {
      queryString = "?";
      Object.entries(query).forEach(([key, value]) => {
        if (![null, undefined].includes(value)) {
          if (queryString !== "?") queryString += "&";
          queryString += `${key}=${encodeURIComponent(value)}`;
        }
      });
      queryString = queryString === "?" ? "" : queryString;
    }
    return url + queryString;
  };
  const push = (path, as, opts) => {
    if (typeof path === "string") urlObject.url = path;
    if (typeof path === "object") urlObject = path;
    const locale = opts?.locale;
    let url = getUrl(locale, urlObject);
    url = appendQuery(url, path.query);
    if (urlObject.app === CURRENT_APP && !url.startsWith("http")) {
      router.push(url, as, opts);
      return null;
    }
    window.location.href = url;
    return null;
  };

  const newRouter = shallowClone(router);
  newRouter.push = push;
  newRouter.appendQuery = appendQuery;
  newRouter.getUrl = getUrl;
  return newRouter;
};
export default useAppNavigator;
