const moneyFormat = (moneyObject) => {
  switch (moneyObject.getPrecision()) {
    case 4:
      return moneyObject.hasCents()
        ? moneyObject.toFormat("$0,0.0000")
        : moneyObject.toFormat("$0,0");
    case 3:
      return moneyObject.hasCents()
        ? moneyObject.toFormat("$0,0.000")
        : moneyObject.toFormat("$0,0");
    default:
      return moneyObject.hasCents()
        ? moneyObject.toFormat("$0,0.00")
        : moneyObject.toFormat("$0,0");
  }
};
export default moneyFormat;
