import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Typography,
  IconButton,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Chip,
} from '@mui/material';
import StyledTableContainer, {
  StyledMenu,
} from 'views/common/components/UI/CommonTable/CommonTable.styles';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import {
  CommonTooltip,
  Typography500,
  LightTextField,
} from 'views/common/components/UI';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const CommonTable = (props) => {
  const { tableHeadItems = [], tableData = [], className, smallRow } = props;
  // FE should handeled this actions outside this component
  const [isClickIcon, setClickIcon] = useState();
  const handleClick = (event) => {
    setClickIcon(event.currentTarget);
  };
  const handleClose = () => {
    setClickIcon(null);
  };

  const renderLink = (cell) => (
    <>
      {cell.tooltipTitle ? (
        <Box display="flex" alignItems="center">
          <CommonTooltip
            tooltipTitle={cell.tooltipTitle}
            tooltipText={cell.tooltipText}>
            <NavigatorLink
              color={cell.color}
              href={cell.href}
              target={cell.target}>
              <Typography500 variant="body2">{cell.text}</Typography500>
            </NavigatorLink>
          </CommonTooltip>
          {cell.chip && (
            <Box component="span" ml={0.75}>
              {cell.chip}
            </Box>
          )}
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <NavigatorLink
            color={cell.color}
            href={cell.href}
            target={cell.target}>
            <Typography500 variant="body2">{cell.text}</Typography500>
          </NavigatorLink>
          {cell.chip && (
            <Box component="span" ml={0.75}>
              {cell.chip}
            </Box>
          )}
        </Box>
      )}
    </>
  );

  const renderButton = (cell) => (
    <>
      {cell.tooltipTitle ? (
        <CommonTooltip
          tooltipTitle={cell.tooltipTitle}
          tooltipText={cell.tooltipText}>
          <Button
            component={NavigatorLink}
            href={cell.href}
            variant={cell.variant}
            color={cell.color}
            onClick={() => cell.cb(cell)}>
            {cell.text}
          </Button>
        </CommonTooltip>
      ) : (
        <Button
          component={NavigatorLink}
          href={cell.href}
          variant={cell.variant}
          size={cell.size}
          color={cell.color}
          startIcon={cell.startIcon}
          onClick={() => cell.cb(cell)}>
          {cell.text}
        </Button>
      )}
    </>
  );
  const renderIconButton = (cell) => (
    <>
      {cell.tooltipTitle || cell.tooltipText ? (
        <CommonTooltip
          tooltipTitle={cell.tooltipTitle}
          tooltipText={cell.tooltipText}>
          <Box className="CTIconButtonWrapper">
            <IconButton
              component={NavigatorLink}
              target={cell.target}
              href={cell.href}
              color={cell.color}
              onClick={() => 'cb' in cell && cell.cb(cell)}>
              {cell.icon}
            </IconButton>
          </Box>
        </CommonTooltip>
      ) : (
        <Box className="CTIconButtonWrapper">
          <IconButton
            component={NavigatorLink}
            target={cell.target}
            href={cell.href}
            color={cell.color}
            onClick={() => 'cb' in cell && cell.cb(cell)}>
            {cell.icon}
          </IconButton>
        </Box>
      )}
    </>
  );
  const renderText = (cell) => (
    <Typography
      variant="body2"
      component="span"
      color={cell.color}
      className={
        (cell.upperCase ? 'CTTextUpperCase' : '',
        cell.fontWeight500 ? 'CTTextBold' : '',
        cell.wrapText ? 'CTwrapText' : '')
      }
      dangerouslySetInnerHTML={{
        __html: cell.text,
      }}
    />
  );
  const renderChip = (cell) => (
    <Chip
      label={cell.text}
      className={`CTChip  ${cell.manyChip ? 'CTManyChip' : ''}`}
      variant={cell.variant}
      color={cell.color}
      component={cell.component}
      href={cell.path}
      clickable={cell.cclickable}
    />
  );
  const renderMenuOptions = (cell) => (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <StyledMenu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={isClickIcon}
        open={isClickIcon}
        onClick={handleClose}>
        <MenuList>
          {cell.menuOptionItems.map((item) => (
            <MenuItem
              key={item.text}
              onClick={handleClose}
              className="CTMenuItme">
              <ListItemIcon className="CTMenuIcon">{item.icon}</ListItemIcon>
              <ListItemText className="CTMenuText">{item.text}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </StyledMenu>
    </>
  );
  const renderTextField = (cell) => (
    <LightTextField
      className={cell.className}
      fullWidth
      label={cell.label}
      value={cell.value}
      whiteBackground={cell.whiteBackground}
      inputProps={{ ...cell.inputProps }}
      onWheel={cell.onWheel}
      onKeyDown={cell.onKeyDown}
      onKeyUp={cell.onKeyUp}
      onChange={(e) => {
        cell.onChange(e, cell);
      }}
    />
  );
  const renderByType = (cell) => {
    switch (cell.type) {
      case 'link':
        return renderLink(cell);
      case 'button':
        return renderButton(cell);
      case 'iconButton':
        return renderIconButton(cell);
      case 'text':
        return renderText(cell);
      case 'chip':
        return renderChip(cell);
      case 'menuOptions':
        return renderMenuOptions(cell);
      case 'textField':
        return renderTextField(cell);
      default:
        return <></>;
    }
  };

  const renderMultiCells = (cells) => (
    <>{cells.map((cell) => renderByType(cell))}</>
  );

  return (
    <>
      <StyledTableContainer
        component={Paper}
        smallRow={smallRow}
        className={className}>
        <Table>
          <TableHead className="THead">
            <TableRow>
              {tableHeadItems.map((cellText, headIndex) => (
                <TableCell
                  className={cellText.className}
                  key={cellText || headIndex}
                  dangerouslySetInnerHTML={{
                    __html:
                      typeof cellText === 'object' ? cellText.text : cellText,
                  }}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={tableHeadItems[index] || index}>
                {row.map((cell, secondIndex) => (
                  <TableCell
                    key={cell.id || `${cell.text}/${secondIndex}`}
                    component="th"
                    className={`${cell.className || ''} ${
                      cell.columnAlignRight ? 'columnAlignRight' : ''
                    } 
                    ${cell.errorRow ? 'CTErrorRow' : ''}
                    ${cell.warningRow ? 'CTWarningRow' : ''}
                    `}
                    scope="row">
                    {cell.multiCells
                      ? renderMultiCells(cell.cells)
                      : renderByType(cell)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
export { CommonTable };
