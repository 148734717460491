import React from "react";
import { Select, Box, InputLabel, useTheme } from "@mui/material";
import StyledFormControl, {
  StyledMenuItem,
} from "views/common/components/UI/TopBar/MenuItemSelect/MenuItemSelect.styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const MenuItemSelect = (props) => {
  const theme = useTheme();

  const {
    icon,
    label,
    value,
    options = [],
    bordered,
    dark,
    handleChange = () => {},
    disabled = false,
    className,
  } = props;

  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: dark
          ? theme.palette.secondary.main
          : theme.palette.common.white,
        minWidth: theme.spacing(25),
        maxWidth: theme.spacing(27),
        borderRadius: 0,
        maxHeight: theme.spacing(36),
      },
    },
    MenuListProps: {
      style: {
        padding: 0,
        color: dark ? theme.palette.common.white : theme.palette.secondary.main,
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  };
  return (
    <StyledFormControl className={`${className} MISFormControl`}>
      <InputLabel
        className="MISLabel"
        variant="standard"
        htmlFor="uncontrolled-native"
      >
        <Box className="MISIcon" component="span">
          {icon}
        </Box>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        value={value}
        MenuProps={MenuProps}
        IconComponent={KeyboardArrowDownIcon}
        className="MISSelect"
      >
        {options?.map((option, index) => (
          <StyledMenuItem
            className="MISMenuItem"
            bordered={bordered?.toString()}
            key={option.id || option.label}
            value={option.id || index}
            onClick={() => {
              handleChange(option);
            }}
            disabled={option.disabled}
            readOnly
          >
            {option.label || option.name}
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export { MenuItemSelect };
