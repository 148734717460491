import React from 'react';
import Image from 'next/image';
import StyledBox from '/views/common/components/UI/ImgBox/ImgBox.styles';
import blur from 'public/images/1x1.webp';

const ImgBox = (props) => {
  const {
    image,
    imgwidth,
    imgheight,
    className,
    objectFit,
    unoptimized,
    ...restOfProps
  } = props;
  return (
    <StyledBox
      className={`IBimgbox ${className}`}
      imgwidth={imgwidth}
      imgheight={imgheight}
      objectFit={objectFit}
    >
      <Image
        className="IBimg"
        data-src={image.src}
        src={image.src}
        alt={image.alt}
        layout="fill"
        unoptimized={unoptimized}
        placeholder="blur"
        blurDataURL={blur.src}
        {...restOfProps}
      />
    </StyledBox>
  );
};
export { ImgBox };
