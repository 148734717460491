/* eslint-disable global-require */
import axios from "axios";

let Router;

if (typeof window !== "undefined") {
  const { default: router } = require("next/router");
  Router = router;
}
const { envVars } = require("next/config").default().publicRuntimeConfig;

const { API_URL } = envVars;

axios.interceptors.request.use(
  (config) => {
    // send dealer id at all request header
    const currentDealer = JSON.parse(localStorage.getItem("currentDealer"));
    const dealerId = currentDealer?.id || null;
    let acceptLangHeader = {};
    try {
      acceptLangHeader = {
        "Accept-Language": Router?.locale || config.headers["Accept-Language"],
      };
    } catch {
      acceptLangHeader = {
        "Accept-Language": config.headers["Accept-Language"],
      };
    }
    return {
      baseURL: config.baseURL || API_URL,
      ...config,
      headers: {
        ...config.headers,
        ...acceptLangHeader,
        "current-dealer-id": dealerId,
      },
    };
  },
  (err) => Promise.reject(err)
);

export default axios;

export const API = {
  dealerTypes: "/dealer_types",
  dealerRegions: "/regions",
  dealerProviders: "/dealer_providers",
  authenticityToken: "/authenticity_token",
  signup: "/signup",
  authenticityToken: "/authenticity_token",
  me: "/me",
  dealerTypes: "/dealer_types",
  dealers: "/dealers",
  cart: "/cart",
  users: {
    signIn: "/auth/sign_in",
    forgotPassword: "/users/password",
    signOut: "auth/sign_out",
  },
  vehicle: {
    id: "/vehicles/{id}",
    imgs: "/vehicles/{id}/images",
    conditionReport: "/vehicles/{id}/condition_report",
    salesContext: "/vehicles/{id}/sales_context",
  },
  availableCheckOutOptions: "/available_checkout_options",
  orders: {
    id: "/orders/{id}",
    activeOrder: "/dealers/{dealer_id}/active_order",
    index: "/orders",
  },
  availableCheckOutOptions: "/available_checkout_options",
  vehicles: {
    index: "/vehicles",
    filter: "/vehicles/filters",
  },
};
