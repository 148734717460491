import { TableContainer, Menu } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

const StyledTableContainer = styled(TableContainer)(({ theme, smallRow }) => ({
  boxShadow: 'none',
  borderRadius: 0,

  '& thead': {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      fontWeight: 500,
      backgroundColor: theme.palette.grey.A000,
      padding: `${theme.spacing(2, 1)} !important`,
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.secondary.main,
    },
  },
  '& tbody': {
    '& .MuiTableRow-root': {
      backgroundColor: `${theme.palette.common.white} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.grey.A000} !important`,
        '& .CTColumnPinning': {
          backgroundColor: `${theme.palette.grey.A000} !important`,
        },
      },
      '& .CTErrorRow': {
        backgroundColor: `${alpha(theme.palette.error.dark, 0.1)} !important`,
        color: theme.palette.error.dark,
        '& .MuiLink-root': {
          '&:not(.MuiButton-root)': {
            color: theme.palette.error.dark,
          },
        },
      },
      '& .CTWarningRow': {
        backgroundColor: `${theme.palette.primary.A000} !important`,
        '& .MuiLink-root': {
          '&:not(.MuiButton-root)': {
            color: theme.palette.grey[500],
          },
        },
      },
      '& .MuiButton-text': {
        padding: theme.spacing(0),
      },
      '&:last-child': {
        '& .MuiTableCell-10818root': {
          borderColor: 'transparent',
        },
      },
    },
    '& .MuiTableCell-root': {
      padding: smallRow
        ? `${theme.spacing(1)} !important`
        : `${theme.spacing(1.5, 1)} !important`,
      fontSize: theme.typography.pxToRem(14),
      whiteSpace: 'nowrap',
      height: smallRow ? 'auto' : theme.spacing(7.75),
      verticalAlign: 'top',
      '&.CTwrapText': {
        maxWidth: theme.spacing(50),
        whiteSpace: 'normal',
        display: 'inline-block',
      },
    },
  },
  '& .MuiTableBody-root': {
    '& .CTColumnPinning': {
      backgroundColor: theme.palette.common.white,
    },
  },
  [theme.breakpoints.up('lg')]: {
    '& .CTColumnPinning': {
      position: 'sticky',
      overflow: 'hidden',
      zIndex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
      borderBottom: 0,
      '&.CTColumnPinningRight': {
        boxShadow: '-1px 0px 3px 0px rgba(0, 0, 0, 0.18)',
        float: 'right',
        right: 0,
      },
      '&.CTColumnPinningLeft': {
        boxShadow: '1px 0px 3px 0px rgba(0, 0, 0, 0.18)',
        float: 'left',
        left: 0,
      },
    },
  },
  '& a': {
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  '&::-webkit-scrollbar': {
    height: theme.spacing(1.25),
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(8),
  },
  '& .CTTextUpperCase': {
    textTransform: 'uppercase',
  },
  '& .CTTextBold': {
    fontWeight: 500,
  },
  '& .columnAlignRight': {
    textAlign: 'end',
  },
  '& .CTIconButtonWrapper': {
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  '& .CTwrapText': {
    display: 'inline-block',
    maxWidth: theme.spacing(50),
    whiteSpace: 'normal',
  },
  '& .CTManyChip': {
    margin: theme.spacing(0, 1, 1, 0),
  },
}));
export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: theme.spacing(40),
    '& .MuiMenu-list': {
      maxHeight: theme.spacing(14),
      padding: 0,
    },
    '& .CTMenuItme': {
      padding: theme.spacing(1.5, 2),
      backgroundColor: theme.palette.common.white,
      '& .CTMenuIcon': {
        '& svg': {
          fontSize: theme.spacing(3),
          color: theme.palette.secondary.light,
        },
      },
    },
    '& .CTMenuText': {
      color: theme.palette.secondary.dark,
    },
    '&::-webkit-scrollbar': {
      width: theme.spacing(1.75),
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.grey.A000,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.light,
      borderRadius: theme.spacing(1),
      border: `${theme.spacing(0.5)} solid ${theme.palette.grey.A000}`,
    },
  },
}));
export default StyledTableContainer;
