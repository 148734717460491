import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";

const PaymentOptionDialog = dynamic(() =>
  import("views/common/components/UI").then((mod) => mod.PaymentOptionDialog)
);
const BuyNowBtn = (props) => {
  const {
    availableCheckOutOptions,
    vehicleId,
    handleCustomAgreeClickBtn,
    fullWidth = true,
    showCheckOutText = false,
  } = props;
  const { t } = useTranslation("common_card_actions");

  const [openPaymentOptionsDialog, setOpenPaymentOptionsDialog] =
    useState(false);
  const togglePaymentOptionsDialog = () => {
    setOpenPaymentOptionsDialog((prevState) => !prevState);
  };

  return (
    <>
      <Button
        variant="contained"
        fullWidth={fullWidth}
        onClick={togglePaymentOptionsDialog}
      >
        {showCheckOutText
          ? t("common_card_actions:checkout")
          : t("common_card_actions:buy_it_now")}
      </Button>
      {openPaymentOptionsDialog && availableCheckOutOptions && (
        <PaymentOptionDialog
          open={openPaymentOptionsDialog}
          handleClose={togglePaymentOptionsDialog}
          availableCheckOutOptions={availableCheckOutOptions}
          vehicleId={vehicleId}
          handleCustomAgreeClickBtn={handleCustomAgreeClickBtn}
        />
      )}
    </>
  );
};

export { BuyNowBtn };
